import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import FooterBar from "../components/footerBar";
import "../css/layout.css";
import { Suspense } from "react";
import Loading from "../components/Loading";
const Layout = () => {
  // const [preLoad, setpreLoad] = useState(true);

  // setTimeout(() => {
  //   setpreLoad(false);
  // }, 1000);
  return (
    <Suspense fallback={<Loading />}>
      <div className="layout">
        <NavBar />
        <Outlet />
        <FooterBar />
      </div>
    </Suspense>
  );
};
export default Layout;
