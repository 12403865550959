import { useState } from "react";
import { Link } from "react-router-dom";

import "../css/navbar.css";

const Menu = () => {
  return (
    <div className="flex flex-col mt-4 space-y-2 lg:-mx-6 lg:mt-0 lg:flex-row lg:space-y-0  p-2 ">
      <Link className="lg:px-6  text-slate-700  hover:text-slate-500" to="/">
        Home
      </Link>
      <Link
        className="lg:px-6  text-slate-700  hover:text-slate-500"
        to="/blogs"
      >
        Blog
      </Link>
      <Link
        className="lg:px-6  text-slate-700  hover:text-slate-500"
        to="/about-us"
      >
        AboutUs
      </Link>
      <Link
        className="lg:px-6  text-slate-700  hover:text-slate-500"
        to="/contact"
      >
        Contact
      </Link>
    </div>
  );
};
// const WhatssApp = () => {
//   return (
//     <button
//       className="fixed z-40 animate-bounce inset-x-0 lg:inset-x-auto bottom-6 lg:right-8 xl:right-10 xl:bottom-8 z-10bg-white   shadow-lg font-normal h-20 w-20 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
//       type="button"
//     >
//       <a
//         href="https://wa.me/+9495161062"
//         className="flex items-center flex-1 w-0"
//       >
//         <span className="flex  rounded-lg">
//           <Logo className="lg:h-10 " />
//         </span>
//       </a>
//     </button>
//   );
// };
const CallBtn = () => {
  return (
    <div className="w-auto fixed z-50 right-0 bottom-4 bg-transparent">
      <div className="flex flex-row relative">
        <div className="w-52 p-2 text-center bg-gray-500 items-center justify-center align-middle hide">
          <span>+9495161062</span>
        </div>
        <div className="w-auto ">
          <div className="call mx-5 h-10 w-10 px-4 py-2 m-1 bg-gray-500 shadow-lg flex items-center justify-center  rounded-full text-white hover:bg-green-400">
            <a href="https://wa.me/+9495161062">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 myDIV"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const NavBar = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  return (
    <>
      <nav className=" fixed top-0 z-50 h-20 drop-shadow-md right-0 left-0 p-6 mx-auto lg:flex lg:justify-between lg:items-center bg-white W-100">
        <div className="flex items-center justify-between">
          <div>
            <Link
              className="text-2xl font-bold  lg:text-3xl text-slate-700  hover:text-slate-500"
              to="/"
            >
              AI Blog
            </Link>
          </div>

          <div className="flex lg:hidden">
            <span
              type="button"
              className="text-black  e"
              aria-label="toggle menu"
              onClick={() => setMenuToggle((prevmenuToggle) => !prevmenuToggle)}
            >
              <svg viewBox="0 0 24 24" className="w-8 h-8 fill-current">
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div className=" hidden lg:block">
          <Menu />
        </div>
        <div
          className="flex lg:hidden bg-white w-full shadow-lg  ease-in-out duration-300"
          onClick={() => setMenuToggle((prevmenuToggle) => !prevmenuToggle)}
        >
          {menuToggle ? <Menu /> : ""}
        </div>
      </nav>
      {/* 
      <WhatssApp /> */}
      <CallBtn />
    </>
  );
};
export default NavBar;
