import aboutImg from "../assets/about.webp";
const aboutUs = () => {
  return (
    <>
      <section>
        <div className=" container">
          <div className="grid grid-cols-1 p-20 mt-20 ">
            <div className="grid lg:grid-cols-2 gap-1 sm:grid-cols-1">
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                      />
                    </svg>
                  </span>
                  <h4 className="bg-clip-text text-transparent bg-gradient-to-bl from-yellow-700 to-slate-500">
                    Lorem ipsum dolor sit amet.
                  </h4>
                </div>
                <div className="flex flex-row gap-2">
                  <h1 className="text-2xl lg:text-5xl md:text-3xl  text-transparent font-bold bg-clip-text bg-gradient-to-tr from-gray-500 to-slate-400">
                    About
                  </h1>
                  <h1 className="text-2xl lg:text-5xl md:text-3xl  text-slate-800 ">
                    Us
                  </h1>
                </div>
                <div className="flex">
                  <p className="mb-10  text-base font-light text-left">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Praesentium sapiente a optio molestiae distinctio vero
                    aliquid, deleniti provident quae omnis quibusdam assumenda
                    iusto, modi laborum hic dolore, quam veniam unde.
                  </p>
                </div>
              </div>
              <div className="">
                <img
                  src={aboutImg}
                  alt="about"
                  className="w-full  grayscale "
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="grid-cols-1"></div>
        </div>
      </section>
    </>
  );
};
export default aboutUs;
