import { Link, useParams } from "react-router-dom";
import data from "../db/data.json";

const Blogdetails = () => {
  const params = useParams();
  let param_id = parseInt(params.id);
  let blog_data = data.blog.find((e) => param_id === parseInt(e.id));

  let rescent_blog = data.blog.filter((e) => {
    if (e.id + 2 < data.blog.length - 1) {
      return param_id - 2 >= parseInt(e.id) && param_id + 2 <= parseInt(e.id);
    }
    if (e.id - 2 < 1) {
      return param_id >= parseInt(e.id);
    } else {
      return param_id - 2 > parseInt(e.id);
    }
  });

  return (
    <>
      <section key={blog_data.id}>
        <article>
          <header className="mx-auto mt-20 max-w-screen-lg rounded-t-lg pt-16 text-center bg-white">
            <p className="text-gray-500">Published {blog_data.Published}</p>
            <h1 className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl">
              {blog_data.title}
            </h1>
            <p className="mt-6 text-lg text-gray-700">{blog_data.title}</p>
            <div className="mt-6 flex flex-wrap justify-center gap-2">
              <button className="rounded-lg bg-gray-100 px-2 py-1 font-medium text-gray-600 hover:bg-gray-200">
                #{blog_data.tag}
              </button>
            </div>
            <img
              className="-z-10 absolute top-0 left-0 mt-10 h-full w-full object-cover "
              src={blog_data.image}
              alt="blog"
            />
          </header>

          <div className="mx-auto max-w-screen-lg space-y-12 rounded-b-lg bg-white  mb-3 px-8 pt-10 pb-20 font-serif text-lg tracking-wide text-gray-700 ">
            <h2 className="text-2xl font-semibold">{blog_data.sub_title}</h2>
            <blockquote className="max-w-lg border-l-4 px-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
              maiores tempora quod ducimus dolore!
              <span className="whitespace-nowrap text-sm">— Daniel Lehmer</span>
            </blockquote>
            <p>{blog_data.discription}</p>
          </div>
        </article>
      </section>

      <div className="w-full">
        <hr className="mb-1 ml-20 h-1 w-full border-none bg-green-400  " />
        <hr className="mb-10 mr-20 h-1 w-10/12 border-none bg-purple-500  " />
      </div>

      <aside
        aria-label="Recent Posts"
        className="mx-auto mt-10 max-w-screen-xl py-20 shadow-lg mb-3"
      >
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="mb-10 md:mb-16">
            <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
              Most Recent Posts
            </h2>
            <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint
              necessitatibus molestias explicabo.
            </p>
          </div>

          <div className="grid gap-8 sm:grid-cols-2 sm:gap-12 lg:grid-cols-2 xl:grid-cols-2 xl:gap-16">
            {rescent_blog.map((e) => {
              return (
                <article
                  key={e.id}
                  className="flex flex-col items-center gap-4 md:flex-row lg:gap-6"
                >
                  <a
                    href="/"
                    className="group shrink-0 relative block h-56 w-full self-start overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-24 md:w-24 lg:h-40 lg:w-40"
                  >
                    <img
                      src={e.image}
                      loading="lazy"
                      alt=""
                      className="group-hover:scale-110 absolute inset-0 h-full w-full object-cover object-center transition duration-200"
                    />
                  </a>

                  <div className="flex flex-col gap-2">
                    <span className="text-sm text-gray-400">{e.Published}</span>

                    <h2 className="text-xl font-bold text-gray-800">
                      <a
                        href="/"
                        className="active:text-rose-600 transition duration-100 hover:text-rose-500"
                      >
                        {e.title}
                      </a>
                    </h2>

                    <p className="text-gray-500">{e.sub_title}</p>

                    <div>
                      <Link
                        to={`/blogs/${e.id}`}
                        className="active:text-rose-700 font-semibold text-rose-500 transition duration-100 hover:text-rose-600"
                      >
                        Read more
                      </Link>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </aside>
    </>
  );
};
export default Blogdetails;
