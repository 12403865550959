import Blogbox from "../components/BlogBox";
import data from "../db/data.json";
const Blogs = () => {
  return (
    <>
      <main>
        <article>
          <header className="mx-auto max-w-screen-2xl pt-28 text-center">
            <p className="text-gray-500">Published April 4, 2022</p>
            <h1 className="mt-2 text-2xl lg:text-5xl md:text-3xl font-bold text-gray-500 sm:text-5xl">
              <span className="text-slate-800"> AI </span>Taking Over{" "}
              <span className="text-slate-800">Sectors</span>
            </h1>
            <p className="mt-6 text-lg text-gray-700">
              Did AI making Every work environment hussle free!
            </p>

            <img
              className="sm:h-[34rem] mt-10 w-full object-cover"
              src="https://images.unsplash.com/photo-1717444309226-c0809d4b5bde?q=80&w=1856&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Featured"
              loading="lazy"
            />
          </header>

          <div className="mx-auto mt-10 max-w-screen-2xl space-y-12 px-10 py-10 font-serif text-lg tracking-wide text-gray-700">
            <strong className="text-2xl font-medium">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime
              impedit ex consequatur nostrum cupiditate at sequi? Ipsam commodi
              modi officia mollitia doloribus tenetur consectetur quae?
            </strong>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
              enim maxime sit laudantium! Dolore atque, maxime iusto ut quas
              distinctio reiciendis animi voluptatibus soluta molestias,
              mollitia officiis laboriosam illum earum.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
              similique reiciendis et recusandae provident repellendus rem
              doloremque eaque error assumenda?
            </p>
          </div>
        </article>
      </main>

      <div className="w-full">
        <hr className="mb-1 ml-20 h-1 w-full border-none bg-gray-400  " />
        <hr className="mb-10 mr-20 h-1 w-10/12 border-none bg-slate-500  " />
      </div>

      <aside
        aria-label="Related Articles"
        className="mx-auto mt-10 max-w-screen-2xl py-20"
      >
        <h2 className="mb-10 text-center text-5xl font-bold   text-slate-500">
          Checkout <span className="text-slate-800">Our</span>Blogs
        </h2>

        <div className=" flex flex-row flex-wrap max-w-screen-2xl justify-center px-1 gap-4 ">
          {data.blog.map((blog) => {
            return (
              <div key={blog.id}>
                <Blogbox blog={blog} />
              </div>
            );
          })}
        </div>
      </aside>
    </>
  );
};
export default Blogs;
