import roundImage from "../assets/roundimage.png";
const contact = () => {
  return (
    <>
      <section className="  ">
        <div className="container flex flex-col mx-auto px-6 py-10 mt-20 space-y-7 lg:h-[40rem] lg:flex-row lg:py-16 lg:text-center ">
          <div className=" lg:w-1/2 text-center border-s-8 border-t-8 border-slate-500 ">
            <div className="lg:max-w-lg p-2 mt-10">
              <h1 className=" mb-4 text-center text-3xl font-bold text-slate-500 lg:text-5xl  sm:text-3xl md:mb-6 md:text-left">
                Contact <span className="text-slate-800">Us</span>
              </h1>
              <p className="mb-10  text-base font-light text-left">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias
                temporibus, consequuntur mollitia molestias error ut et ullam
                voluptatem harum eveniet quod! Minus rem quis cum iusto, natus
                eius voluptatem porro. Lorem ipsum dolor sit amet, consectetur
                adipisicing elit. In eaque corrupti distinctio blanditiis at
                tenetur consequuntur dicta unde quod commodi! Aut eum voluptate
                nam libero ipsa veritatis commodi id sequi.
              </p>
            </div>

            <button className="ml-2 w-1/2  bg-slate-500 hover:bg-gradient-to-r from-purple-600 to-green-500 shadow-lg lg:h-[4rem] rounded-full text-white">
              contact Us
            </button>
          </div>
          <div className="lg:w-1/2 flex items-end justify-end border-e-8 border-b-8 border-gray-600 ">
            <img
              src={roundImage}
              alt="contact"
              loading="lazy"
              className="filter  grayscale drop-shadow-lg"
            />
          </div>
        </div>
      </section>
      <div className="grid grid-cols-3 grid-row-1 sm:grid-cols-2 sm:px-8 sm:py-12 sm:gap-x-8 md:py-16">
        <div className="relative z-10 col-start-2 row-start-1 px-4 pt-40 pb-3 bg-gradient-to-t from-black sm:bg-none">
          <p className="text-2xl lg:text-5xl md:lg-3xl font-medium text-white sm:mb-1 sm:text-gray-500">
            Looking <span className="text-slate-800"> Forward!</span>
          </p>
          <h2 className="text-xl font-semibold text-white sm:text-xl sm:leading-7 sm:text-black md:text-2xl">
            Let,s Keep in Touch!
          </h2>
          <div className="col-start-2 row-start-2 px-4 sm:pb-16">
            <div className="flex items-center text-sm font-medium my-5 sm:mt-2 sm:mb-4">
              <div>
                <p className="mb-4 text-base font-light">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Distinctio quis quia veniam, esse dolore facere repellat
                  consequatur illum, est laudantium quam, sint expedita?
                  Expedita quia illo cum. Architecto, iusto cumque?
                </p>
              </div>
            </div>
            <hr className="w-16 border-gray-300 hidden sm:block" />
          </div>
          <div className="col-start-1 row-start-3 space-y-3 px-4">
            <p className="flex items-center text-black text-sm font-medium ">
              <img
                src="https://images.pexels.com/photos/18497713/pexels-photo-18497713/free-photo-of-back-of-a-young-woman-standing-in-a-field.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="w-6 h-6 rounded-full mr-2 bg-gray-100"
              />
              feel free to Connect!
            </p>
            <button
              type="button"
              className=" text-white bg-slate-500 text-base font-semibold px-6 py-2 rounded-lg"
            >
              contactUs
            </button>
          </div>
        </div>

        <div className="col-start-1 row-start-1 flex sm:col-start-1 sm:row-span-3">
          <div className="w-full grid grid-cols-3 grid-rows-2 gap-2">
            <div className="relative col-span-3 row-span-2 md:col-span-2 ">
              <img
                src="https://images.pexels.com/photos/18497713/pexels-photo-18497713/free-photo-of-back-of-a-young-woman-standing-in-a-field.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="absolute inset-0 w-full h-full object-cover bg-gray-100 sm:rounded-lg"
              />
            </div>
            <div className="relative hidden md:block">
              <img
                src="https://images.pexels.com/photos/19175643/pexels-photo-19175643/free-photo-of-woman-in-coat-standing-by-wall-in-tunnel.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="absolute inset-0 w-full h-full object-cover rounded-lg bg-gray-100"
              />
            </div>
            <div className="relative hidden md:block">
              <img
                src="https://images.pexels.com/photos/12901642/pexels-photo-12901642.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="absolute inset-0 w-full h-full object-cover rounded-lg bg-gray-100"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default contact;
