import { Link } from "react-router-dom";

const BlogBox = (props) => {
  return (
    <article
      key={props.blog.id}
      className="mx-auto w-96 my-4 flex flex-col overflow-hidden  border border-gray-300 bg-white text-gray-900 transition hover:translate-y-2 hover:shadow-lg"
    >
      <div>
        <img
          src={props.blog.image}
          className="h-56 w-full object-cover"
          alt=""
        />
        <div className="flex-auto px-6 py-5">
          <span className="mb-2 flex items-center text-sm font-semibold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
              />
            </svg>
            {props.blog.tag}
          </span>
          <h3 className="mt-4 mb-3 text-xl font-semibold xl:text-2xl">
            {props.blog.title}
          </h3>
          <p className="mb-4 text-base font-light">{props.blog.discription}</p>

          <Link
            to={`/blogs/${props.blog.id}`}
            className="inline-block cursor-pointer select-none rounded-full border border-white bg-slate-500 hover:bg-gradient-to-r from-green-400 to-purple-500 px-2 py-1 text-center align-middle text-sm font-semibold leading-normal text-white no-underline shadow-sm"
          >
            Read Now
          </Link>
        </div>
      </div>
    </article>
  );
};
export default BlogBox;
